


































import Vue from "vue";
import ConditionDisplay from "./ConditionDisplay.vue";
import { AssignedGoal } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";
import { ProgressFill } from "@/components/common";

@Component({ components: { ConditionDisplay, ProgressFill } })
export default class AssignedGoalItem extends Vue {
  @Prop({ default: () => [] }) goal!: AssignedGoal;

  get assigner() {
    const prefix = this.$t("goals.assigner");
    const creatorName = this.goal.Creator.Username;
    const creatorEmail = this.goal.Creator.Email;
    return `${prefix} ${creatorName} (${creatorEmail})`;
  }
  get text() {
    const number = this.goal.IsCompleted ? "all" : this.goal.Progress;
    const plural = this.goal.Progress === 1 ? "" : "s";
    const dynMsg = this.$t("dashboardPage.yourGoalsCard.haveCompleted", {
      number,
      plural,
    }).toString();
    return dynMsg;
  }
}
