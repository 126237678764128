

















































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { ConditionType, Condition } from "@/core/models";

@Component
export default class ConditionCard extends Vue {
  conds: Condition[] = [];
  dueDateDialog = false;
  get items() {
    return [
      {
        text: this.$t("goals.condtype.date"),
        value: ConditionType.DueDate,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.sent"),
        value: ConditionType.Sentiment,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.pitch"),
        value: ConditionType.Pitch,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.vol"),
        value: ConditionType.Volume,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.ppm"),
        value: ConditionType.Pauses,
        disabled: false,
      },
      {
        text: this.$t("goals.condtype.wpm"),
        value: ConditionType.SpeechSpeed,
        disabled: false,
      },
    ];
  }

  handleTypeChange(i: number) {
    if (this.conds[i].Type === ConditionType.SessionScore)
      this.conds[i].Value = 50;
    if (this.conds[i].Type === ConditionType.Sentiment)
      this.conds[i].Value = [20, 70];
    if (this.conds[i].Type === ConditionType.Pitch)
      this.conds[i].Value = [300, 500];
    if (this.conds[i].Type === ConditionType.Volume)
      this.conds[i].Value = [10, 50];
    if (this.conds[i].Type === ConditionType.Pauses)
      this.conds[i].Value = [5, 10];
    if (this.conds[i].Type === ConditionType.SpeechSpeed)
      this.conds[i].Value = [80, 120];
    if (this.conds[i].Type === ConditionType.DueDate)
      this.conds[i].Value = new Date().toISOString().substr(0, 10);

    this.refreshItems();
  }

  refreshItems() {
    this.items.forEach(i => (i.disabled = false));
    let takenTypes = this.conds.map(c => c.Type);
    if (takenTypes.length > 0)
      takenTypes.forEach(t => {
        const item = this.items.find(i => i.value === t);
        if (item) item.disabled = true;
      });
  }

  removeCondition(index: number) {
    if (this.conds.length === 1) this.conds = [];
    this.conds.splice(index, 1);
    this.refreshItems();
  }

  addCondition() {
    this.conds.push(Condition.empty());
  }

  @Watch("conds", { deep: true })
  onChanged() {
    this.$emit("update", this.conds);
  }
}
