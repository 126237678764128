







import Vue from "vue";
import { DateTime } from "luxon";
import { Component, Prop } from "vue-property-decorator";
import { Condition, ConditionType } from "@/core/models";

@Component
export default class ConditionDisplay extends Vue {
  @Prop({ default: () => Condition.empty() }) condition!: Condition;
  get isDueDate() {
    return this.condition.Type === ConditionType.DueDate;
  }
  get value() {
    let val = this.condition.Value;
    if (this.condition.Type === ConditionType.DueDate) {
      const d = DateTime.fromISO(this.condition.Value);
      const now = DateTime.fromMillis(Date.now());
      if (d < now) return this.$t("goals.condmsg.overdue").toString();
      const rel = d.setLocale(this.$i18n.locale).toRelative();
      return `${this.$t("goals.condmsg.duein")} ${rel}`;
    }

    const msgstr = `goals.condmsg.${this.condition.Type}`;
    switch (this.condition.Type) {
      case ConditionType.SessionScore:
        return this.$t(msgstr, { n: val });
      case ConditionType.SpeechSpeed:
        val = (val as string).split(";").map(n => +n);
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Sentiment:
        val = (val as string).split(";").map(n => +n);
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Pitch:
        val = (val as string).split(";").map(n => +n);
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Volume:
        val = (val as string).split(";").map(n => +n);
        return this.$t(msgstr, { n1: val[0], n2: val[1] });
      case ConditionType.Pauses:
        val = (val as string).split(";").map(n => +n);
        return this.$t(msgstr, { n1: val[0], n2: val[1] });

      default:
        return "";
    }
  }
}
