





















































import Vue from "vue";
import { Condition } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import ConditionsSelect from "./ConditionsSelect.vue";
import { UsersSelect, NumberSelect } from "@/components/common";

@Component({ components: { UsersSelect, NumberSelect, ConditionsSelect } })
export default class AddGoalButton extends Vue {
  @Getter("groups/loading") loading!: boolean;
  @Getter("groups/usersManaged") users!: string[];
  @Getter("profile/getUserEmail") userEmail!: string;
  @Getter("groups/retrieved") groupsRetrieved!: boolean;
  @Getter("goals/addGoalLoading") addGoalLoading!: boolean;

  @Action("goals/addGoal") addGoalAction!: Function;
  @Action("groups/getGroups") getGroups!: Function;

  showDialog = false;
  targetSessions = 5;
  conditions: Condition[] = [];
  assignOneself = false;
  selectedUsers: string[] = [];

  get canSubmit() {
    if (this.targetSessions <= 0) return false;
    if (this.selectedUsers.length == 0 && !this.assignOneself) return false;
    if (this.conditions.some(c => c.Type === "" || c.Value === ""))
      return false;
    return true;
  }

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }
  addGoal() {
    if (this.addGoalLoading) return;

    if (this.assignOneself) this.selectedUsers.push(this.userEmail);
    let conds = this.conditions.map(c => {
      if (Array.isArray(c.Value))
        return { Type: c.Type, Value: c.Value.join(";") };
      return c;
    });
    let data = {
      target: this.targetSessions,
      conditions: conds,
      assignedUsers: this.selectedUsers,
    };
    this.addGoalAction(data);
    this.toggleDialog();
  }

  created() {
    if (!this.groupsRetrieved) this.getGroups();
  }
}
